

/* Resource Card Icons */
.fa-user-md, .fa-notes-medical, .fa-clinic-medical, .fa-teeth, .fa-tooth {
    font-size: 4em;
    margin: 1rem;
    text-align: center;
}

/* Seperation Line */
hr {
    border-top: 2px solid #b4b4b4;
    width: 95%;
    margin-top: 0.3rem;
    margin-bottom: 0.1rem;
}



/* Footer */
footer {
    background-color: #3f3f3f;
    color: #d5d5d5;
    padding-top: 2rem;
}

/* Media Queries for Responsiveness */
@media (min-width: 576px) {
    .hero-section {
        height: 450px;
    }

    .hero-section h1{
        /*margin-top: 10%;*/
    }

}

@media (min-width: 768px) {
    .hero-section {
        background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
        url("https://firebasestorage.googleapis.com/v0/b/bludentistry-d1204.appspot.com/o/Images%2FBillboard%2Fslide1.jpg?alt=media&token=02c67876-6f9c-4a02-b20a-219cdb83c614");
        background-size: cover;
        height: 500px;
    }

    .hero-section h1 {
        /*margin-top: 15%;*/
    }
}

@media (min-width: 992px) {
    .hero-section {
        /* color: blue; */
        height: 525px;
    }
}

@media (min-width: 1200px) {
    .hero-section {
        height: 475px;
    }

    .hero-section h1 {
        /*margin-top: 6%;*/
    }
}